import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import config from '../config/awsConfig';
const userPool = new CognitoUserPool({
  UserPoolId: config.userPoolId,
  ClientId: config.clientId,
});


export const authenticate = (username, password, setCognitoUserData, setisLogin, setSubmitForm, setAlertMessage) => {

  return new Promise((resolve, reject) => {
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        localStorage.setItem('token', 'success');
        setisLogin(true);
        setSubmitForm(false)

        //remove this afterwards, just for debuging purposes
        // delete after
        const cognitoUser = userPool.getCurrentUser();
        cognitoUser.getSession(function(error, session) {
          if(error) {
           console.log(error);
           return;
          }
          if(session) {
            console.log('ssssss', session.accessToken.payload.username)
            localStorage.setItem('idToken', session.idToken.jwtToken)
            localStorage.setItem('userID', session.accessToken.payload.username)
          }
         });
         
        
      },
      onFailure: (err) => {
        setAlertMessage(err.message)
        setSubmitForm(false)
        setisLogin(false);
        localStorage.setItem('token', 'error');
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        console.log('New password required!');
        delete userAttributes.email_verified;
        delete userAttributes.email;
        setCognitoUserData({
          isFirstLogin: true,
          user: cognitoUser,
          userAttr: userAttributes,
          resolve :resolve,
          reject : reject
        })
      },
    });
  });

};

export const signOut = () => {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.signOut();
  }
};

export const isAuthenticated = () => {
  const cognitoUser = userPool.getCurrentUser();
  return cognitoUser;
};
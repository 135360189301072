const awsConfig= {
    userPoolId: 'eu-central-1_tLSkI8ka0',
    clientId: '4q46kmkv4ebaadg65etemsdf49',
  };

  // for developer testing

  // const awsConfig= {
  //   userPoolId: 'eu-north-1_1Y8N741MZ',
  //   clientId: '54c6joe8miu9r26s64p5qvh4b0',
  // };

export default awsConfig;
  
import  { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { LoginContext } from '../Application/App';
import { signOut } from '../BackendServices/Authentication';

const Logout = () => {
  const {setisLogin} = useContext(LoginContext)
  const nav = useNavigate();

useEffect(() => {
  signOut()
  localStorage.clear()
  setisLogin(false)
  nav("/");  
}, [])


}

export default Logout
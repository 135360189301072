import React, { createContext, useState, lazy, Suspense } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Logout from "../Helpers/Logout";
import CircularProgress from '@mui/material/CircularProgress';
import { Grid } from '@mui/material';


export const LoginContext = createContext();
export const HandleDrawerContext = createContext();

const drawerWidth = 240;

const Home = lazy(() => import('../Pages/Home'));
const NewService = lazy(() => import('../Pages/NewService'));
const Login = lazy(() => import('../Pages/Login'));
const RiskData = lazy(() => import('../Pages/RiskData'));


function App() {
  const [open, setOpen] = useState(false);
  const [boxPadding, setboxPadding] = useState(3)

  const handleDrawerOpen = () => {
    setOpen(true);
    setboxPadding(30)
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setboxPadding(3);
  };

  const  token = () => {
    const tokenString = localStorage.getItem('token');
    return tokenString
  };
  const [isLogin, setisLogin] = useState(token())
  const [emailErr, setemailErr] = useState(false)
  const [passErr, setpassErr] = useState(false)
  const [newpassErr, setNewpassErr] = useState(false)
  const [cognitoUserData, setCognitoUserData] = useState([])
  const [oldpass, setoldPass] = useState('')
  const [newpass, setNewPass] = useState('')
  const [submitForm, setSubmitForm] = useState(false)
  const [submitFormNewPass, setSubmitFormNewPass] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')


  if(!isLogin || isLogin === 'error') {
    return ( 
    <LoginContext.Provider value={{isLogin, setisLogin, emailErr, setemailErr, passErr, setpassErr, cognitoUserData, setCognitoUserData, oldpass, setoldPass,newpass, setNewPass,newpassErr, setNewpassErr, submitForm, setSubmitForm,alertMessage, setAlertMessage, submitFormNewPass, setSubmitFormNewPass}}>
        <Suspense fallback={
          <Grid container justifyContent="center" alignItems="center" height="100vh">
            <CircularProgress />
          </Grid>
        }>
          <Login />
        </Suspense>
    </LoginContext.Provider>
    )
  }

  return (
    <BrowserRouter>
    <LoginContext.Provider value={{isLogin, setisLogin}}>
      <HandleDrawerContext.Provider value={{handleDrawerOpen, handleDrawerClose, open, boxPadding,drawerWidth}}>
      <Suspense fallback={
          <Grid container justifyContent="center" alignItems="center" height="100vh">
            <CircularProgress />
          </Grid>
        }>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route exact path="/home" element={<Home />} />
              <Route exact path="/Logout" element={<Logout />} />
              <Route exact path="/newService" element={<NewService />} />
              <Route exact path="/riskdata" element={<RiskData />} />
            </Routes>
        </Suspense>
      </HandleDrawerContext.Provider>
    </LoginContext.Provider>
    </BrowserRouter>

  );
}

export default App;
export {drawerWidth};
